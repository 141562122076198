import React, { createContext, useContext, useState } from "react";

const endpoint = 'https://embracereleasenotes.azurewebsites.net/api/ValidateToken';

const AuthContext = createContext({});

const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuthContext() {
  return useContext(AuthContext);
}

function useProvideAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const validateToken = (token: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "x-functions-key": '0DlejNuW2U3ZCKYv266FhxgaExATLLxsmQQU29zgSzU0OPhSIPBsyg==',
        },
        body: JSON.stringify({
            token: token
        })
      }).then((response) => {
        if(response.status === 204){
          setIsAuthenticated(true);
          resolve();
        } else {
          setIsAuthenticated(false);
          reject()
        }
      }).catch(() => {
        setIsAuthenticated(false);
        reject()
      })
    })
  };

  return {
    isAuthenticated,
    validateToken
  };
}

export { useAuthContext, AuthProvider };
