import React, { Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Login from './components/login'
import { AuthProvider, useAuthContext } from './contexts/authContext';
import Group from '@embracesbs/component-group';


const ProtectedRoute = ({ component: Component, ...routeProps }) => {
  const auth = useAuthContext();

  return (<Route {...routeProps} render={(props) => 
    !auth.isAuthenticated ? (<Redirect to={{ pathname: "/login" }} />) : <Component {...props} />
  } />)
}

const Home = lazy(() => import('./components/home'));
const OverView = lazy(() => import('./components/overview'));
const Detail = lazy(() => import('./components/detail'));

/**
 * App
 */
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<Group justify="center" alignContent="center">Loading...</Group>}>
          <ProtectedRoute exact path="/" component={Home} />
          <ProtectedRoute exact path="/overview/:tag" component={OverView}/>
          <ProtectedRoute exact path="/detail/:tag/:area" component={Detail} />
          <Route exact path="/login/" component={Login} />
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export default App;