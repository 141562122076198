import React, { useState } from 'react';
import styled from '@emotion/styled';
import {Helmet} from 'react-helmet';
import { withRouter } from 'react-router-dom'
import { theme, unit } from '@embracesbs/helpers';
import Text from '@embracesbs/component-text';
import Image from '@embracesbs/component-image';
import Card from '@embracesbs/component-card';
import TextField from '@embracesbs/component-textfield';
import Button from '@embracesbs/component-button';
import Animation from '@embracesbs/component-animation';
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper';
import logo from '../../assert/embrace_logo.png';
import lock from '../../assert/lock.json';
import { useAuthContext } from "../../contexts/authContext"


const StyleLoginCard = styled(Card)`
   flex-direction: row;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   padding: 24px;
   width: ${unit(678)};
   height: ${unit(378)};
   font-family: 'Nunito Sans', sans-serif;
   src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');
   color: #0C1C47;
`;

const StyledHeader = styled(Card)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${unit(80)};
    align-items: center;
    justify-content: center;
    background-color: ${theme.color.light};
`;

const HalfDiv = styled.div`
    width: 50%;
    position: relative;
`;

const StyledButton = styled(Button)`
        background-color:#225CFF;
        :hover {
            background-color:#225CFF;
        }
    `;

const StyledText = styled(Text)`
    font-family: 'Nunito Sans', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');
    color: #0C1C47;
`;

const StyledTextField = styled(TextField)`
    font-family: 'Nunito Sans', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');
    color: #0C1C47;
`;

const StyledTextFieldWrapper = styled(TextFieldWrapper)`
    font-family: 'Nunito Sans', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');
    color: #0C1C47;
`;



/**
 * Login
 */
const Login = ({ history }) => {
    const [token, setToken] = useState("")
    const [ loginFailed, setLoginFailed ] = useState(false);
    const [ loggin, setLoggin ] = useState(false);
    const auth = useAuthContext()
    
    var md5 = require('md5');
    const handleInputChange = (e) => {
        setToken(md5(e.target.value));
    };

    const login = () => {
        setLoggin(true);
        window.setTimeout(() => {
            auth.validateToken(token).then(() => {
                history.replace({ pathname: "/" });
            }).finally(() => {
                setLoggin(false);
                setLoginFailed(true);
            })
        }, 1000);
    }
 

    return (
    <>
        <StyledHeader>
            <Image src={logo} width="200"  />
        </StyledHeader>
        <Helmet>
            <style>{`body { background-color: ${theme.color.dark6}; }`}</style>
        </Helmet>
        <StyleLoginCard dp={8} height={96}>
                <HalfDiv>
                    <StyledText textStyle="h3" content="Welkom bij de release notes" 
                        extraProps={['style']} 
                        style={{ paddingTop: unit(20) }}
                    />
                    <StyledText textStyle="h5" content="Voer het wachtwoord in om door te gaan naar de release notes"
                        extraProps={['style']} 
                        style={{ paddingTop: unit(10), paddingBottom: unit(10) }}
                    />
                    <StyledTextFieldWrapper labelText="Wachtwoord"
                        extraProps={['style']} 
                        style={{ padding: 0 }}
                    >
                        <StyledTextField
                            onChange={handleInputChange}
                            type="password"
                            placeholder="Voer het wachtwoord in"
                            hasAnError={loginFailed} 
                            errorMessage={loginFailed && "login mislukt"}
                            errorMessageColor="danger"
                        />
                    </StyledTextFieldWrapper>
                    <StyledButton text="Login" onClick={login}
                        extraProps={['style']} 
                        style={{ position: 'absolute', bottom: 0, right: 0 }}
                    />
                </HalfDiv>
                <HalfDiv>
                    <Animation
                        isPaused={!loggin}
                        animation={lock}
                        extraProps={['style']}
                        style={{ marginTop: '96px' }}
                    /> 
                </HalfDiv>
        </StyleLoginCard>
    </>

    )
};

export default withRouter(Login);